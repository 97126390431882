export const amplifyConfig = {
   Auth: {
        region: 'eu-west-1',
        userPoolId: 'eu-west-1_UejFu8840',
        userPoolWebClientId: '46l5l4jm0q273v2c796tqgm6om',
        oauth: {
            domain: 'generic-event-dev.auth.eu-west-1.amazoncognito.com',
            scope: ['email', 'profile', 'openid'],
            redirectSignIn: 'https://event-dev.biomerieux.com',
            redirectSignOut: 'https://event-dev.biomerieux.com',
            clientId: "46l5l4jm0q273v2c796tqgm6om",
            responseType: "code"
        },
    },
    Logger: {
        // logLevel: ERROR | WARN | INFO | DEBUG | VERBOSE
        logLevel: 'ERROR'
    }
};