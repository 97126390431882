import React, { useEffect, useState } from 'react';
import Chat from './chat/Chat';

import Amplify, { Auth, Hub } from 'aws-amplify';
import { amplifyConfig } from '../amplify.config';

Amplify.configure(amplifyConfig);

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getUser().then(userData => setUser(userData));
          break;
        case 'signOut':
          setUser(null);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
        default:
            break;
      }
    });
    
    getUser().then(userData => {
      if(!userData) {
        return Auth.federatedSignIn({provider:"auth.biomerieux.com"})
      }
      setUser(userData)
    })
    
  }, []);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then(userData => userData)
      .catch(() => console.log('Not signed in'));
  }
    
  return (
    <div className="App full-width full-height">
    {user &&
      <Chat />
    }
    </div>    
  );
}

export default App;